import React, { createContext, ReactNode, useContext, useState } from "react";
import { FormValues } from "./VehicleLookup";

interface VehicleLookupContextProps {
  formValues: FormValues;
  setFormValues: (values: FormValues) => void;
  lookupType: string;
  setLookupType: (type: string) => void;
}

const VehicleLookupContext = createContext<
  VehicleLookupContextProps | undefined
>(undefined);

export const VehicleLookupProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [formValues, setFormValues] = useState<FormValues>({
    vin: "",
    licenseplate: "",
    state: "",
  });
  const [lookupType, setLookupType] = useState<string>("vin");

  return (
    <VehicleLookupContext.Provider
      value={{ formValues, setFormValues, lookupType, setLookupType }}
    >
      {children}
    </VehicleLookupContext.Provider>
  );
};

export const useVehicleLookup = (): VehicleLookupContextProps => {
  const context = useContext(VehicleLookupContext);
  if (!context) {
    throw new Error(
      "useVehicleLookup must be used within a VehicleLookupProvider",
    );
  }
  return context;
};
